import { Component } from "react";

import AdComponent from "./AdComponent";

// This number will always stay 0 on server side
let instance = 0;

class AdWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instanceNum: 0,
    };
  }

  componentDidMount() {
    // Increment instance on client side
    instance++;
    this.setState({ instanceNum: instance });
  }

  render() {
    const { adType, id } = this.props;
    if (
      this.state.instanceNum &&
      (process.env.NODE_ENV === "development" ||
        process.env.NEXT_PUBLIC_BASE_URL.includes(
          window.location.host.replace("www.", "")
        ))
    )
      // instanceNum > 0 on client side, therefore render ad
      return (
        <AdComponent
          instanceNum={this.state.instanceNum + 10}
          adType={adType}
          id={id}
        />
      );
    return null;
  }
}

export default AdWrapper;
