import useSWR, { preload } from "swr";

const fetcher = async (url) => {
  const res = await fetch(url);

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    // Attach extra info to the error object.
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

function useRequest(url, { ...options }) {
  return useSWR(url, fetcher, options);
}

function preloadRequest(url, { ...options }) {
  return preload(url, fetcher);
}

export { preloadRequest };

export default useRequest;
