import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

import styles from "./Socials.module.css";

function Socials({ children }) {
  return (
    <div className={styles.links}>
      {children}
      <ul className="list-inline text-center">
        <li className="list-inline-item">
          <a
            href="https://www.facebook.com/kvrxaustin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://twitter.com/kvrx?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://www.youtube.com/user/KVRXAustin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </li>
        <li className="list-inline-item">
          <a
            href="https://www.instagram.com/kvrxaustin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Socials;
