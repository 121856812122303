import Footer from "./footer";
import Audio from "@/components/Audio";
import ClientOnly from "@/components/ClientOnly";
import Ad from "@/components/Ads/Ad";
import AdBar from "@/components/Ads/AdBar";
import Socials from "@/components/common/Socials";
import Navbar from "./navbar";
import BackgroundImage from "./background-image";
import { useUser } from "../contexts/user";
import Scrobble from "../utils/scrobble";
import UserMenu from "./user-menu";
import Mailchimp from "./mailchimp";
import Banner from "./banner";

function Layout({ children }) {
  const { isAuthenticated } = useUser();
  return (
    <>
      <ClientOnly>
        <Audio />
        <BackgroundImage />
        {isAuthenticated && <UserMenu />}
        {isAuthenticated && <Scrobble />}
      </ClientOnly>
      <Navbar />
      <div className="main">
        <div className="container">
          <Banner />
          <div role="alert" className="text-center alert alert-dark">
            <strong>
              For underwriting information, please e-mail{" "}
              <a
                className="alert-link"
                href="mailto:advertise@texasstudentmedia.com"
              >
                advertise@texasstudentmedia.com
              </a>
              .
            </strong>
          </div>
          <Ad adType="banner" id="banner-ad" />
        </div>
        {children}
        <div className="container">
          <AdBar />
          <Socials />
          <Mailchimp />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Layout;
