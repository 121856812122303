import dynamic from "next/dynamic";
import useInView from "react-cool-inview";

const Ad = dynamic(() => import("./Ad"));

function AdBar() {
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  });

  return (
    <div className="row" ref={observe}>
      {inView && (
        <>
          <div className="col-12 col-md-auto mx-auto my-3">
            <Ad adType="top" id="ad-1" />
          </div>
          <div className="col-12 col-md-auto mx-auto my-3">
            <Ad adType="middle" id="ad-2" />
          </div>
          <div className="col-12 col-md-auto mx-auto my-3">
            <Ad adType="bottom" id="ad-3" />
          </div>
        </>
      )}
    </div>
  );
}

export default AdBar;
