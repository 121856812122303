import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { Fade } from "react-bootstrap";

import Image from "@/components/Image";
import usePrevious from "src/lib/use-previous";
import styles from "./Layout.module.css";

function BackgroundImage({ src, onLoadingComplete }) {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  return (
    <Fade in={backgroundLoaded}>
      <div className={styles.bg}>
        <Image
          alt="gradient"
          src={src || "/app/images/gradients/default.jpg"}
          layout="fill"
          objectFit="cover"
          quality={100}
          onLoadingComplete={() => {
            setBackgroundLoaded(true);
            onLoadingComplete();
          }}
        />
      </div>
    </Fade>
  );
}

const PATHS_TO_BACKGROUNDS = [
  { path: "/", exact: true, src: "/app/images/gradients/home.jpg" },
  { path: "/blog", src: "/app/images/gradients/blog.jpg" },
  { path: "/podcasts", src: "/app/images/gradients/podcasts.jpg" },
];

let key = 0;
function BackgroundImageHandler() {
  const timer = useRef();
  const { asPath } = useRouter();
  const prevPath = usePrevious(asPath);
  const prevSrc = useRef("");
  const [backgrounds, setBackgrounds] = useState([]);

  useEffect(() => {
    const onLoadingComplete = () => {
      // Handle potential race conditions
      if (timer.current) clearTimeout(timer.current);

      // Delay unmounting our previously set background until our new background fades in.
      timer.current = setTimeout(() => {
        setBackgrounds((backgrounds) =>
          backgrounds.length > 1
            ? [backgrounds[backgrounds.length - 1]]
            : backgrounds
        );
      }, 5000);
    };

    // Check if our path changed before trying to add a background
    if (asPath !== prevPath) {
      // Update our unique key for each background
      key += 1;

      // Check if asPath === something
      let src;
      for (let i = 0; i < PATHS_TO_BACKGROUNDS.length; i++)
        if (
          // Check if path should be exact then do ===
          (PATHS_TO_BACKGROUNDS[i].exact &&
            asPath === PATHS_TO_BACKGROUNDS[i].path) ||
          // If paths aren't supposed to be exact then check if the path includes
          (!PATHS_TO_BACKGROUNDS[i].exact &&
            asPath.includes(PATHS_TO_BACKGROUNDS[i].path))
        ) {
          src = PATHS_TO_BACKGROUNDS[i].src;
          break;
        }

      // Add background to our list to render if the src is different
      if (src !== prevSrc.current)
        setBackgrounds((backgrounds) => [
          ...backgrounds,
          <BackgroundImage
            key={key}
            onLoadingComplete={onLoadingComplete}
            src={src}
          />,
        ]);
      // Update our previous src
      prevSrc.current = src;
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [asPath, prevPath]);

  return backgrounds;
}

export default BackgroundImageHandler;
