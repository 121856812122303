import { useState } from "react";
import { useRouter } from "next/router";
import { FaSearch } from "react-icons/fa";

function SearchBar() {
  const [value, setValue] = useState("");
  const router = useRouter();

  return (
    <form
      action="/app/search/"
      method="get"
      onSubmit={(e) => {
        e.preventDefault();
        router.push({
          pathname: "/search",
          query: { q: value },
          shallow: true,
        });
      }}
    >
      <div className="input-group">
        <input
          type="search"
          className="form-control border-0 text-white bg-transparent rounded-0"
          placeholder="Search..."
          aria-label="Search"
          name="q"
          onChange={(e) => setValue(e.target.value)}
        />
        <button className="input-group-text btn" type="submit">
          <FaSearch />
        </button>
      </div>
    </form>
  );
}

export default SearchBar;
