import axios from "axios";

async function fetchAPI(relativePath, { ...options }) {
  const res = await axios(`${process.env.NEXT_PUBLIC_BASE_URL}${relativePath}`, {
    ...options,
    headers: { ...options.headers, Accept: "application/json" },
  });
  return await res.data;
}

export default fetchAPI;
