import { useEffect, useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import axios from "axios";

import { useNowPlaying } from "../contexts/now-playing";
import { useUser } from "../contexts/user";

function Scrobble() {
  const [selectSong, {}] = useMutation(selectSongMutation);
  const { deejay, id, isAuthenticated } = useUser();
  const { song, playlist } = useNowPlaying();
  const interval = useRef();
  const lastFmUsername =
    typeof window !== "undefined" &&
    window.localStorage.getItem("LAST_FM_USERNAME");

  useEffect(() => {
    if (
      isAuthenticated &&
      lastFmUsername &&
      parseInt(id) === playlist.user_id
    ) {
      // Clear the interval just in case
      if (interval.current) clearInterval(interval.current);

      // Create our interval
      interval.current = setInterval(async () => {
        // Get now playing song
        const res = await axios({
          url: "https://ws.audioscrobbler.com/2.0/",
          params: {
            method: "user.getrecenttracks",
            user: lastFmUsername,
            api_key: process.env.NEXT_PUBLIC_LAST_FM_API_KEY,
            format: "json",
            // Needed to avoid caching in responses
            timestamp: new Date().getTime(),
          },
        }).catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
          return error.response;
        });
        // Last.fm might be having backend issues here
        if (res && res.status !== 200) return;

        const d = await res.data;
        // Handle recenttracks not existing
        if (!d.recenttracks) return;
        const latestTrack = d.recenttracks.track[0];

        // Check if now playing
        if (latestTrack["@attr"]?.nowplaying === "true") {
          const title = latestTrack.name.toLowerCase();
          const artist = latestTrack.artist["#text"].toLowerCase();
          // const album = latestTrack.album["#text"];

          // Only iterate through the playlist if the song isn't our current song
          if (
            song.track.toLowerCase() !== title ||
            // Handle multiple artists by checking if there is a comma then check if it includes
            // our artist
            song.artist.includes(",")
              ? !song.artist.toLowerCase().includes(artist)
              : // Otherwise just check if artist ===
                song.artist.toLowerCase() !== artist
          ) {
            for (let i = 0; i < playlist.songs.length; i++) {
              const s = playlist.songs[i];
              if (
                // Check if we have the current song
                title === s.title.toLowerCase() && s.artist.includes(",")
                  ? s.artist.toLowerCase().includes(artist)
                  : s.artist.toLowerCase() === artist &&
                    // Additional check to not update when we don't need to
                    song.id !== parseInt(s.id)
              ) {
                selectSong({
                  variables: {
                    id: s.id,
                    title: s.title,
                    artist: s.artist,
                  },
                });
              }
            }
          }
        }
      }, 5000);
    } else {
      clearInterval(interval.current);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [deejay, id, isAuthenticated, selectSong, song, playlist, lastFmUsername]);

  return null;
}

export default Scrobble;

const selectSongMutation = gql`
  mutation SelectSong($id: Int!, $title: String, $artist: String) {
    selectSong(id: $id, title: $title, artist: $artist) {
      song {
        title
        artist
      }
    }
  }
`;
