const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;

// https://developers.google.com/analytics/devguides/collection/analyticsjs/pages
const pageview = (url) => {
  // Sets the page value on the tracker.
  window.ga("set", "page", url);
  // Sending the pageview no longer requires passing the page
  // value since it's now stored on the tracker object.
  window.ga("send", "pageview");
};

// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
const event = ({ action, category, label, value }) => {
  window.ga("send", "event", {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    value: value,
  });
  // This code is only for tracking live stream plays and pauses on GA4
  // Note that "action" is being passed in as an "event" (action being "play" or "pause")
  // https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
  gtag("event", action);
};

export { GA_TRACKING_ID, pageview, event };
