import {
  createContext,
  useContext,
} from "react";
import useRequest, { preloadRequest } from "src/lib/use-request";

const NOW_PLAYING_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/now_playing/track/`
const NOW_PLAYING_PLAYLIST_URL = `${process.env.NEXT_PUBLIC_BASE_URL}/now_playing/playlist/`
const REFRESH_INTERVAL = 3000;

const AppContext = createContext();

// Preload the data before rendering the context
preloadRequest(NOW_PLAYING_URL);
preloadRequest(NOW_PLAYING_PLAYLIST_URL);

const NowPlayingProvider = ({ children }) => {
  const { data: nowPlaying, error: nowPlayingError } = useRequest(
    NOW_PLAYING_URL,
    {
      keepPreviousData: true,
      refreshInterval: REFRESH_INTERVAL,
      refreshWhenHidden: true,
      fallbackData: {
        artist: "91.7FM KVRX",
        track: "None of the Hits, All of the Time",
        id: 0,
        album: "",
        category: "MUS",
        genre: "",
        show: "KVRX Automation",
        album_artwork: "/static/main/images/default_album_art.png",
        album_href: "",
        playlist_id: "",
      },
    }
  );
  const { data: nowPlayingPlaylist, error: nowPlaylingPlaylistError } = useRequest(
    NOW_PLAYING_PLAYLIST_URL,
    {
      keepPreviousData: true,
      refreshInterval: REFRESH_INTERVAL,
      refreshWhenHidden: true,
      fallbackData: {
        mode: "automation",
        playlist_id: null,
        last_updated: null,
        show: { id: null, show_name: "KVRX Automation", url: "/" },
        songs: [],
      },
    }
  );

  const contextProps = {
    song: nowPlaying,
    playlist: nowPlayingPlaylist,
  };

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

function useNowPlaying() {
  return useContext(AppContext);
}

export { useNowPlaying };

export default NowPlayingProvider;
