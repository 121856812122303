import { createContext, useContext, useState } from "react";
import { event } from "src/lib/ga";

const AppContext = createContext();

const PlayerStateProvider = ({ children }) => {
  const [playerState, setPlayerState] = useState({
    isListeningToShowRecording: false,
    isLoading: false,
    isPlaying: false,
    src: "https://kvrx.org/now_playing/stream",
  });

  const toggleLivestream = () => {
    setPlayerState((state) => {
      const isPlaying = !state.isPlaying;
      if (isPlaying) event({ category: "Livestream", action: "play" });
      else event({ category: "Livestream", action: "pause" });

      return {
        ...state,
        isPlaying: !state.isPlaying,
        isLoading: !state.isPlaying,
      };
    });
  };
  const setLoading = (status) => {
    setPlayerState((state) => ({ ...state, isLoading: status }));
  };
  const listenToRecording = (src) => {
    setPlayerState((state) => ({
      ...state,
      src: src,
      isListeningToShowRecording: true,
      isPlaying: true,
    }));
  };
  const listenToLivestream = () => {
    setPlayerState((state) => ({
      ...state,
      src: "https://kvrx.org/now_playing/stream",
      isListeningToShowRecording: false,
      isPlaying: true,
    }));
  };

  const contextProps = {
    ...playerState,
    toggleLivestream,
    setLoading,
    listenToRecording,
    listenToLivestream,
  };

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

function usePlayerState() {
  return useContext(AppContext);
}

export { usePlayerState };

export default PlayerStateProvider;
