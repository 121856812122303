import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";

// Gets the ad data based on adType
function getAdData(adType, instanceNum) {
  let adId, adElementId;
  if (adType === "top") {
    adId = "/57935331/kvrx_toprectangle_300x250";
    adElementId = "dfp-ad-kvrx_toprectangle_300x250" + "-" + instanceNum;
  } else if (adType === "middle") {
    adId = "/57935331/KVRX_middlerectangle_300x250";
    adElementId = "dfp-ad-kvrx_middlerectangle_300x250" + "-" + instanceNum;
  } else if (adType === "bottom") {
    adId = "/57935331/kvrx_bottomrectangle_300x250";
    adElementId = "dfp-ad-kvrx_bottomrectangle_300x250" + "-" + instanceNum;
  } else if (adType === "banner") {
    adId = "/57935331/kvrx_leaderboard_728x90";
    adElementId = "dfp-ad-kvrx_leaderboard_728x90" + "-" + instanceNum;
  }
  return [adId, adElementId];
}

function AdComponent({ adType, id, instanceNum }) {
  const router = useRouter();
  const adRef = useRef(null);
  const [adId, setAdId] = useState("");
  const [adElementId, setAdElementId] = useState("");

  /* HOOKS */
  // Handle setting adElementId when instanceNum changes
  useEffect(() => {
    const [newAdId, newAdElementId] = getAdData(adType, instanceNum);
    setAdId(newAdId);
    setAdElementId(newAdElementId);
  }, [adType, instanceNum]);

  // Handle creating the ad when adElementId changes
  useEffect(() => {
    const { googletag } = window;
    let slot = null;

    if (adId && adElementId)
      googletag.cmd.push(function () {
        try {
          // Get our ad slot
          let tmp = null;
          if (adType === "banner")
            tmp = googletag.defineSlot(adId, [728, 90], adElementId);
          else tmp = googletag.defineSlot(adId, [300, 250], adElementId);

          // If we were able to make an ad slot, display it
          if (tmp) {
            tmp.addService(googletag.pubads());
            googletag.display(tmp);
          }

          // Keep track of our ad slot
          slot = tmp;
        }
        catch (e) {
          console.log(e);
        }
        adRef.current = slot;
      });

    return () => {
      // Handle destroying our old slot
      googletag.cmd.push(function () {
        try {
          googletag.destroySlots([slot]);
        } catch (e) {
          console.log(e);
        }
      });
    };
  }, [adElementId, adId, adType]);

  // This is needed for when the component gets reloaded
  useEffect(() => {
    const { googletag } = window;
    if (adRef.current)
      // Check if we have an ad slot
      googletag.cmd.push(function () {
        try {
          googletag.display(adElementId);
        } catch (e) {
          console.log(e);
        }
      });
    // We only refresh if the component gets reloaded
  }, [adElementId]);

  // Handle refreshing the ad on router changes
  useEffect(() => {
    const handleRouteChange = () => {
      const { googletag } = window;
      googletag.cmd.push(function () {
        try {
          googletag.pubads().refresh([adRef.current]);
        } catch (e) {
          console.log(e);
        }
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, adRef]);
  /* END HOOKS */

  return (
    <div className="text-center" id={id}>
      <div
        id={adElementId}
        className="dfp-tag-wrapper"
        style={
          // Do this to handle layout shifts
          adType === "banner"
            ? { minWidth: 728, minHeight: 90 }
            : { minWidth: 300, minHeight: 250 }
        }
      ></div>
    </div>
  );
}

export default AdComponent;
