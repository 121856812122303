import { useEffect, useState } from "react";
import { Fade } from "react-bootstrap";
import {
  MdPlayArrow as PlayArrowIcon,
  MdPause as PauseIcon,
} from "react-icons/md";

import { useNowPlaying } from "@/components/contexts/now-playing";
import Image from "@/components/Image";
import { usePlayerState } from "@/components/contexts/player";

function Player() {
  const [show, setShow] = useState(false);
  const { isLoading, isPlaying, toggleLivestream } = usePlayerState();
  const { song: currentSong } = useNowPlaying();
  if (isLoading) {
    var playerIcon = (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  } else if (isPlaying)
    playerIcon = (
      <PauseIcon className="align-self-center" style={{ fontSize: "35px" }} />
    );
  else
    playerIcon = (
      <PlayArrowIcon
        className="align-self-center"
        style={{ fontSize: "35px" }}
      />
    );

    // TODO: TRACK PREV VALUE
  useEffect(() => {
    setShow(false);
    setTimeout(() => setShow(true), 1000);
  }, [currentSong]);

  return (
    <div className="row">
      <div className="col">
        <Fade in={show}>
          <div className="row">
            <div className="col-auto d-flex">
            <a href="https://kvrx.org/player/" className="col-auto d-flex">
              <Image
                width={50}
                height={50}
                src={currentSong.album_artwork}
                alt={currentSong.album}
                layout="fixed"
                priority={true}
              />
            </a>
            </div>
            <div className="col overflow-hidden text-nowrap px-0">
              <span className="text-white">{currentSong.track}</span>
              <br />
              <span className="text-muted">
                {currentSong.artist} — {currentSong.album}
              </span>
            </div>
          </div>
        </Fade>
      </div>
      <div className="col-auto d-flex" onClick={toggleLivestream}>
        <div className="btn btn-link text-white px-0">{playerIcon}</div>
      </div>
    </div>
  );
}

export default Player;
