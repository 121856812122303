import Link from "next/link";
import { useRouter } from "next/router";
import { Collapse } from "react-bootstrap";

import Socials from "../../common/Socials";

import config from "src/config";
import styles from "./Nav.module.css";

function Nav(props) {
  const router = useRouter();

  return (
    <Collapse in={props.display}>
      <div className={`${styles.overlay} w-100 d-lg-none`}>
        <div
          className="container text-center"
          style={{ height: `calc(100vh - ${props.otherHeight}px)` }}
        >
          {config.links.map(({ href, title, exact }, i) => (
            <Link key={i} href={href}>
              <a
                onClick={props.toggle}
                className={
                  (exact && router.pathname === href) ||
                  (!exact && router.pathname.includes(href))
                    ? styles.active
                    : null
                }
              >
                <b>{title}</b>
              </a>
            </Link>
          ))}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://hornraiser.utexas.edu/project/44032?utm_source=kvrx&utm_campaign=KVRXdonatebutton&utm_medium=referral"
          >
            <b>Donate</b>
          </a>
          <Socials>
            <p className="text-muted text-start">
              <strong>Connect With Us</strong>
            </p>
          </Socials>
        </div>
      </div>
    </Collapse>
  );
}

export default Nav;
