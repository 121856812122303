import { gql, useQuery } from "@apollo/client";

// TODO: Switch to our Image component
import { Image } from "react-bootstrap";

const Banner = () => {
  const { data } = useQuery(query);
  // Don't render if no events
  if (!data || !data.allEvents.length) return null;
  console.log(data);

  // Get the first event (expected to be most recent upcoming)
  const { title, image, url } = data.allEvents[0];
  // If there's no images then no point of having a banner
  if (!image) return null;

  // Base element
  let el = (
    <Image
      alt={title}
      src={process.env.NEXT_PUBLIC_BASE_URL + "/media/" + image}
      fluid
    />
  );
  // If there's a url then add a wrapper
  if (url)
    el = (
      <a href={url} target="_blank" rel="noreferrer">
        {el}
      </a>
    );

  return <div className="mb-3">{el}</div>;
};

export default Banner;

const query = gql`
  query Events {
    allEvents {
      title
      date
      image
      url
    }
  }
`;
