import { useEffect } from "react";
import { useRouter } from "next/router";
import Script from "next/script";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apolloClient";
import { SSRProvider } from "@react-aria/ssr";
import NProgress from "nprogress";

import Layout from "../components/Layout";
import * as ga from "../lib/ga";

import NowPlayingProvider from "@/components/contexts/now-playing";
import UserProvider from "@/components/contexts/user";
import PlayerStateProvider from "@/components/contexts/player";

import "../styles/main.scss";
import "../styles/globals.css";

function MyApp({ Component, pageProps }) {
  const LayoutComponent = Component.Layout || Layout;
  const apolloClient = useApollo(pageProps);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = (url) => {
      NProgress.start();
    };
    const handleRouteChangeComplete = (url) => {
      ga.pageview(url);
      NProgress.done();
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);

  return (
    <>
      {/* Global Site Tag - Google Ads */}
      <Script
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        strategy="lazyOnload"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window.googletag = window.googletag || {cmd: []};

          googletag.cmd.push(function () {
            googletag.pubads().enableAsyncRendering();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs();
            googletag.pubads().enableLazyLoad();
            googletag.enableServices();
          });
          `,
        }}
      />
      {/* Global Site Tag - Google Analytics */}
      <Script
        src="//www.google-analytics.com/analytics.js"
        strategy="lazyOnload"
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `
          window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
          ga("create", "${ga.GA_TRACKING_ID}", {
              "cookieDomain": "auto"
          });
          ga("require", "displayfeatures");
          ga("send", "pageview");
          `,
        }}
      />
      {/* NEW GA4 Global Site Tag - Google Analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-MNBFCR0MLD"
        strategy="lazyOnload"
      />
      <Script
        dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-MNBFCR0MLD');
          `,
        }}
      />
      <ApolloProvider client={apolloClient}>
        <SSRProvider>
          <NowPlayingProvider>
            <PlayerStateProvider>
              <UserProvider>
                <LayoutComponent>
                  <Component {...pageProps} />
                </LayoutComponent>
              </UserProvider>
            </PlayerStateProvider>
          </NowPlayingProvider>
        </SSRProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
