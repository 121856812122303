import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

import fetchAPI from "./utils/fetch-API";
import Notification from "@/components/Notification";
import { gql, useMutation } from "@apollo/client";
import { useNowPlaying } from "./contexts/now-playing";

function SongSelectionModal({ as: Component, children, showModal, ...props }) {
  const { song: currentSong, playlist } = useNowPlaying();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Component {...props} onClick={handleShow}>
        {children}
      </Component>

      <Modal id="song-select" size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select a Song</Modal.Title>
        </Modal.Header>
        <ModalBody
          currentSong={currentSong}
          playlist={playlist}
          mode={playlist.mode}
        />
        <ModalFooter
          playlist={playlist}
          mode={playlist.mode}
          handleClose={handleClose}
        />
      </Modal>
    </>
  );
}

function ModalBody({ currentSong, playlist, mode }) {
  const [selectSong, { data: selectSongData }] =
    useMutation(selectSongMutation);
  const [notifications, setNotifications] = React.useState([]);

  useEffect(() => {
    if (selectSongData && selectSongData.selectSong) {
      const { title, artist } = selectSongData.selectSong.song;
      setNotifications((notifications) => [
        ...notifications,
        {
          message: `${title} by ${artist} is now playing!`,
        },
      ]);
    }
  }, [selectSongData]);

  if (mode === "automation")
    var body = <p className="text-center">Automation Mode is on!</p>;
  else if (playlist.songs.length === 0)
    body = <p className="text-center">No playlist loaded!</p>;
  else
    body = (
      <React.Fragment>
        <p>
          <small>You can click on a track to skip to that track</small>
        </p>
        {notifications.map((notification, i) => (
          <Notification key={i} {...notification} />
        ))}
        <table className="table table-condensed">
          <thead>
            <tr>
              <th></th>
              <th>Song Title</th>
              <th>Artist</th>
              <th className="d-none d-sm-table-cell">Album Title</th>
            </tr>
          </thead>
          <tbody>
            {playlist.songs.map((song, i) => {
              return (
                <tr
                  className={
                    currentSong.id === parseInt(song.id) ? "table-active" : ""
                  }
                  onClick={() =>
                    selectSong({
                      variables: {
                        id: song.id,
                        title: song.title,
                        artist: song.artist,
                      },
                    })
                  }
                  key={song.id}
                  style={{ cursor: "pointer" }}
                >
                  <td>{i + 1}</td>
                  <td>{song.title}</td>
                  <td>{song.artist}</td>
                  <td className="d-none d-sm-table-cell">{song.album}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );

  return <Modal.Body>{body}</Modal.Body>;
}

function ModalFooter({ mode, playlist, handleClose }) {
  const automationMode = mode === "automation";

  return (
    <Modal.Footer>
      <Button
        className="me-auto"
        onClick={() =>
          fetchAPI(
            `${automationMode ? "/playlist-mode" : "/automation-mode"}`,
            { withCredentials: true }
          )
        }
      >
        {automationMode ? "Playlist" : "Automation"} Mode
      </Button>
      {playlist.playlistId ? (
        <a
          href={`/playlists/${playlist.show.id}/${playlist.playlistId}/edit/`}
          role="button"
          className="btn btn-default"
        >
          Edit Playlist
        </a>
      ) : null}
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  );
}

export default SongSelectionModal;

const selectSongMutation = gql`
  mutation SelectSong($id: Int!, $title: String, $artist: String) {
    selectSong(id: $id, title: $title, artist: $artist) {
      song {
        title
        artist
      }
    }
  }
`;
