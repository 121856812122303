import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";

function Notification({ error, message }) {
  const [show, setShow] = useState(true);

  // Automatically hide after certain amount of time
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    show && (
      <Alert
        className={error ? "alert-danger" : "alert-success"}
        onClose={() => setShow(false)}
        dismissible
      >
        {message || error.message}
      </Alert>
    )
  );
  //   return (
  //     <Toast>
  //       <Toast.Header>
  //         {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
  //         <strong className="me-auto">KVRX.org</strong>
  //         {/* <small>11 mins ago</small> */}
  //       </Toast.Header>
  //       <Toast.Body>{message}</Toast.Body>
  //     </Toast>
  //   );
}

export default Notification;
