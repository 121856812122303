import { useRef } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNowPlaying } from "../contexts/now-playing";
import SongSelectionModal from "../SongSelectionModal";
import fetchAPI from "../utils/fetch-API";

import styles from "./UserMenu.module.css";

const UserMenu = () => {
  const [selectSong, {}] = useMutation(selectSongMutation);
  const { song, playlist } = useNowPlaying();
  const ref = useRef();
  const automationMode = playlist.mode === "automation";
  const lastFmUsername =
    typeof window !== "undefined" &&
    window.localStorage.getItem("LAST_FM_USERNAME");

  // Get the index for our current song
  let prevSong, nextSong;
  for (let i = 0; i < playlist.songs.length; i++) {
    // We found our current song
    if (playlist.songs[i].id === song.id)
      (prevSong = playlist.songs[i - 1]), (nextSong = playlist.songs[i + 1]);
  }

  return (
    <div
      className={`${styles.container} bg-dark position-fixed shadow d-none d-lg-block p-3 rounded-3`}
    >
      {/* Automation/Playlist Mode Button */}
      <button
        role="button"
        type="button"
        className="btn btn-primary mb-3"
        onClick={() =>
          fetchAPI(
            `${automationMode ? "/playlist-mode" : "/automation-mode"}`,
            { withCredentials: true }
          )
        }
      >
        Switch to {automationMode ? "Playlist" : "Automation"} Mode
      </button>
      {playlist.playlist_id ? (
        <>
          {/* Prev/Next Song Buttons */}
          <div className="row">
            <div className="col-6">
              <button
                role="button"
                type="button"
                className="btn btn-link"
                onClick={() =>
                  selectSong({
                    variables: {
                      id: prevSong.id,
                      title: prevSong.title,
                      artist: prevSong.artist,
                    },
                  })
                }
                disabled={!prevSong}
              >
                Previous Song
              </button>
            </div>
            <div className="col-6">
              <button
                role="button"
                type="button"
                className="btn btn-link"
                onClick={() =>
                  selectSong({
                    variables: {
                      id: nextSong.id,
                      title: nextSong.title,
                      artist: nextSong.artist,
                    },
                  })
                }
                disabled={!nextSong}
              >
                Next Song
              </button>
            </div>
          </div>
          {/* Select a Specific Song */}
          <SongSelectionModal
            as={({ children, ...props }) => <div {...props}>{children}</div>}
          >
            <button role="button" type="button" className="btn btn-link">
              Select a Specific Song
            </button>
          </SongSelectionModal>
          {lastFmUsername ? (
            <div>
              <p>Last.fm User: {lastFmUsername}</p>
              <button
                role="button"
                type="button"
                className="btn btn-link w-100"
                onClick={() => {
                  localStorage.removeItem("LAST_FM_USERNAME");
                  window.location.reload();
                }}
              >
                Clear Last.fm
              </button>
            </div>
          ) : (
            <div>
              <p>Synchronize Last.fm</p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  window.localStorage.setItem(
                    "LAST_FM_USERNAME",
                    ref.current.value
                  );
                  window.location.reload();
                }}
              >
                <div className="input-group">
                  <input
                    className="form-control border-0 text-white bg-transparent px-0"
                    placeholder="Username"
                    ref={ref}
                  />
                  <button className="btn input-group-text px-0" type="submit">
                    Connect
                  </button>
                </div>
              </form>
            </div>
          )}
        </>
      ) : (
        <p>Load a Playlist to see controls.</p>
      )}
    </div>
  );
};

export default UserMenu;

const selectSongMutation = gql`
  mutation SelectSong($id: Int!, $title: String, $artist: String) {
    selectSong(id: $id, title: $title, artist: $artist) {
      song {
        title
        artist
      }
    }
  }
`;
