import { createContext, useContext, useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";

const AppContext = createContext();
const DEFAULT_USER = {
  id: null,
  deejay: {
    id: null,
    dj: "",
    pic: "",
    role: "DJ",
    url: "",
  },
  isAuthenticated: false,
  isSuperuser: false,
  username: "",
  firstName: "",
  lastName: "",
  email: "",
  isStaff: false,
  isActive: false,
  groups: [],
};

const LOGIN_POLL_RATE = 5000;

const UserProvider = ({ children }) => {
  const { data, refetch, startPolling, stopPolling } = useQuery(query, {
    fetchPolicy: "no-cache",
  });
  const [user, setUser] = useState(
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("USER"))
      : DEFAULT_USER
  );

  useEffect(() => {
    if (data && data.me) {
      // User is logged in so we'll poll for log out
      setUser(data.me);
      window.localStorage.setItem("USER", JSON.stringify(data.me));
      startPolling(LOGIN_POLL_RATE);
    } else {
      // User isn't currently logged in
      if (window.localStorage.getItem("USER"))
        // The user has logged in before, so we'll poll for future logins
        startPolling(LOGIN_POLL_RATE);
      
      setUser(DEFAULT_USER);
    }

    return () => {
      stopPolling();
    };
  }, [data, startPolling, stopPolling]);

  const contextProps = {
    ...user,
    refetch,
  };

  return (
    <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>
  );
};

function useUser() {
  return useContext(AppContext);
}

const query = gql`
  query GetMe {
    me {
      id
      deejay {
        id
        dj
        pic
        role
        url
      }
      isAuthenticated
      isSuperuser
      username
      firstName
      lastName
      email
      isStaff
      isActive
    }
  }
`;

export { query, useUser };

export default UserProvider;
