import { useMemo } from "react";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { concatPagination } from "@apollo/client/utilities";
import { SentryLink } from "apollo-link-sentry";
import cookie from "cookie";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";

const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

function createApolloClient({ req }) {
  const authLink = setContext(async (_, { headers }) => {
    const { csrftoken, sessionid } = cookie.parse(
      (req
        ? req.headers.cookie
        : typeof window !== "undefined" && window.document.cookie) || ""
    );

    return {
      headers: {
        ...headers,
        cookie: `sessionid=${sessionid};csrftoken=${csrftoken}"`,
      },
    };
  });

  const link = authLink.concat(
    createHttpLink({
      uri: `${process.env.NEXT_PUBLIC_BASE_URL}/graphql/`, // Server URL (must be absolute)
      credentials: "include", // Additional fetch() options like `credentials` or `headers`
    })
  );

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([
      new SentryLink({
        uri: `${process.env.NEXT_PUBLIC_BASE_URL}/graphql/`, // Server URL (must be absolute)
      }),
      link,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
            playlistsByShowId: {
              keyArgs: ["showId"],
              merge(existing = { objects: [] }, incoming) {
                return {
                  ...incoming,
                  objects: existing.objects.concat(incoming.objects),
                };
              },
            },
          },
        },
      },
    }),
  });
}

function initializeApollo(initialState = null, context = {}) {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

function useApollo(pageProps) {
  // Apollo client instance will be updated only when the cache value has changed
  const state = pageProps ? pageProps[APOLLO_STATE_PROP_NAME] : null;
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}

export { APOLLO_STATE_PROP_NAME, initializeApollo, addApolloState, useApollo };
