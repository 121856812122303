import { useState } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { Collapse, NavDropdown } from "react-bootstrap";
import { FaHeadphonesAlt, FaSearch } from "react-icons/fa";
import Image from "@/components/Image";
import { useUser } from "@/components/contexts/user";
import Player from "./player";
import Overlay from "./MobileLayout/Nav";

import config from "src/config";
import styles from "./Navbar.module.css";
import SearchBar from "./search";

const UserDropdown = dynamic(() => import("./user-dropdown"), { ssr: false });

function Navbar() {
  const router = useRouter();
  const user = useUser();
  const [displayPlayer, setDisplayPlayer] = useState(true);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);

  const togglePlayer = () =>
    setDisplayPlayer((displayPlayer) => !displayPlayer);
  const toggleSearch = () =>
    setDisplaySearch((displaySearch) => !displaySearch);
  const toggleDisplayOverlay = () =>
    setDisplayOverlay((displayOverlay) => !displayOverlay);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark fixed-top ${styles.navbar} flex-column py-0 shadow`}
    >
      <div className="container border-bottom px-0">
        {displayOverlay ? (
          <button
            type="button"
            aria-label="Close navigation"
            className="border-0 p-1 shadow-none navbar-toggler btn-close"
            onClick={toggleDisplayOverlay}
            style={{ width: 30, height: 30 }}
          ></button>
        ) : (
          <button
            type="button"
            aria-label="Toggle navigation"
            className="border-0 p-1 shadow-none navbar-toggler"
            onClick={toggleDisplayOverlay}
          >
            <span className="navbar-toggler-icon" />
          </button>
        )}
        <Link href="/">
          <a className="d-flex p-0 mx-auto me-lg-3 navbar-brand">
            <Image
              width="60px"
              height="35px"
              layout="fixed"
              src="/static/main/images/logo-yellow.png"
              alt="91.7 KVRX"
              priority={true}
            />
          </a>
        </Link>
        <div className="navbar-collapse collapse">
          <div className="navbar-nav w-100">
            {config.links.map((link, i) => {
              if (link.dropdown)
                return (
                  <NavDropdown key={i} title={link.title.toUpperCase()}>
                    {link.dropdown.map((link, j) => (
                      <Link href={link.href} key={j} passHref>
                        <NavDropdown.Item
                          className={`text-uppercase bg-transparent nav-link${
                            (link.exact && router.pathname === link.href) ||
                            (!link.exact && router.pathname.includes(link.href))
                              ? " active"
                              : ""
                          }`}
                        >
                          {link.title}
                        </NavDropdown.Item>
                      </Link>
                    ))}
                  </NavDropdown>
                );

              return (
                <Link key={i} href={link.href}>
                  <a
                    className={`text-uppercase nav-link${
                      (link.exact && router.pathname === link.href) ||
                      (!link.exact && router.pathname.includes(link.href))
                        ? " active"
                        : ""
                    }`}
                  >
                    {link.title}
                  </a>
                </Link>
              );
            })}
            <div className="nav-item ms-lg-auto">
              <a
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://hornraiser.utexas.edu/project/44032?utm_source=kvrx&utm_campaign=KVRXdonatebutton&utm_medium=referral"
              >
                DONATE
              </a>
            </div>
          </div>
        </div>
        <div
          onClick={togglePlayer}
          className="btn btn-link border-start rounded-0 text-white"
        >
          <FaHeadphonesAlt />
        </div>
        <div
          onClick={toggleSearch}
          className="btn btn-link border-start rounded-0 text-white"
        >
          <FaSearch />
        </div>
        {user.isAuthenticated && <UserDropdown user={user} />}
      </div>
      <header className="w-100">
        <div className="container px-0">
          <Collapse in={displayPlayer}>
            <div className="border-bottom">
              <Player />
            </div>
          </Collapse>
          <Collapse in={displaySearch}>
            <div className="border-bottom">
              <SearchBar />
            </div>
          </Collapse>
        </div>
      </header>
      <Overlay
        display={displayOverlay}
        toggle={toggleDisplayOverlay}
        otherHeight={39 + (displayPlayer ? 51 : 0) + (displaySearch ? 36 : 0)}
      />
    </nav>
  );
}

export default Navbar;
