const config = {
  title: "KVRX 91.7 FM",
  titleTemplate: "%s | KVRX 91.7 FM",
  description: "None of the hits, all of the time",
  siteUrl: "https://kvrx.org",
  image: "",
  social: {
    twitter: "KVRX",
  },
  links: [
    { href: "/", title: "Home", exact: true },
    { href: "/schedule", title: "Schedule" },
    { href: "/reviews", title: "Album Reviews" },
    { href: "/blog", title: "Blog" },
    { href: "/podcasts", title: "Podcasts" },
    {
      href: "/about",
      title: "About",
      dropdown: [
        { href: "/about", title: "About Us" },
        { href: "/dj", title: "Deejays", exact: true },
        { href: "/dj/staff", title: "Staff" },
      ],
    },
    { href: "/contact", title: "Contact" },
  ],
};

export default config;
