import { useRef } from "react";
import ReactPlayer from "react-player";

import { usePlayerState } from "./contexts/player";

function Audio() {
  const { isPlaying, src, setLoading } = usePlayerState();
  const player = useRef(null);

  if (isPlaying)
    return (
      <ReactPlayer
        playing={isPlaying}
        controls={false}
        url={src}  
        height="0"
        width="0"
        ref={player}
        onPlay={() => setLoading(false)}
        onBuffer={() => setLoading(true)}
      />
    );
  else return null;
}

export default Audio;
